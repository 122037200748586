import { Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import {
  Box,
  VStack,
  Divider,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";
import {
  FaDatabase,
  FaDiscord,
  FaEthereum,
  FaFacebookSquare,
  FaRegEnvelope,
  FaTwitter,
  FaUsers,
  FaWallet,
} from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BsFillGearFill } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import type { IconType } from "react-icons/lib";

interface FeatureProps {
  icon: IconType;
  heading: string;
  text: string;
}

const features = [
  {
    icon: FaWallet,
    heading: "Multichain support",
    text: "Hype supports Ethereum, Flow, Solana, Avalanche, Polygon, and Klatyn wallets. Don’t see your chain? Don’t fret, it’s coming soon.",
  },
  {
    icon: FaTwitter,
    heading: "Twitter Away",
    text: "Ask your community to connect their Twitter, follow an account, or even retweet a tweet. Bot problems? We’ve got minimum follower and account creation date conditions.",
  },
  {
    icon: FaDiscord,
    heading: "Empower your Discord",
    text: "Ask your community to connect and join your Discord. Running a campaign for a subset of your community? Only allow specific roles to register or users who joined your server before a certain date.",
  },
  {
    icon: FaRegEnvelope,
    heading: "Email Verification",
    text: "Create a more permanent connection with your community. Ask for emails and build a newsletter for important campaign updates.",
  },
  {
    icon: FaUsers,
    heading: "Collabs",
    text: "Collaborate with other communities with token-gating, even cross-chain collaborations!",
  },
  {
    icon: FaDatabase,
    heading: "Data in, data out",
    text: "Do you know who’s in your community? Export your data and connect with them on socials or email.",
  },
  {
    icon: FaFacebookSquare,
    heading: "Market your campaign more effectively",
    text: "Enable performance marketing by adding a Facebook / Meta Pixel ID.",
  },
  {
    icon: IoBarChartOutline,
    heading: "Detailed Analytics",
    text: "Monitor your campaign’s registrations, view count, and requirements that your community is struggling with.",
  },
  {
    icon: BsFillGearFill,
    heading: "API Access",
    text: "Pull the current state of your campaign at any time or a specific community member’s status.",
  },
  {
    icon: FaEthereum,
    heading: "ERC20 Token Validation",
    text: "Check token balances and holdings for any ERC20 token.",
  },
  {
    icon: FiPlusCircle,
    heading: "Mint and distribute ERC-721 NFTs",
    text: "Easily reward your community with event POAPs, special collection drops, 1/1s, and more.",
  },
];

const Feature = ({ icon, heading, text }: FeatureProps) => {
  return (
    <GridItem
      p={6}
      borderWidth={2}
      borderColor={useColorModeValue("gray.700", "gray.200")}
      color={useColorModeValue("gray.800", "gray.200")}
      borderRadius="xl"
    >
      <Heading h={"114px"} fontSize={"22px"} fontWeight={500}>
        <Icon style={{ marginBottom: "-3px" }} as={icon}></Icon> {heading}
      </Heading>
      <Text fontSize="16px" fontWeight={useColorModeValue(300, 200)}>
        {text}
      </Text>
    </GridItem>
  );
};

export default function Features() {
  return (
    <Box as={Container} maxW="7xl" my={10} mt={0} p={4}>
      <Grid>
        <GridItem colSpan={1}>
          <VStack alignItems="flex-start" spacing="20px">
            <Heading
              fontWeight={500}
              color={useColorModeValue("gray.800", "gray.200")}
              fontFamily="'Bungee Shade', cursive"
            >
              Feature Set
            </Heading>
          </VStack>
        </GridItem>
      </Grid>
      <Divider
        my={8}
        opacity={1}
        borderColor={useColorModeValue("gray.800", "gray.100")}
        backgroundColor={useColorModeValue("gray.800", "gray.100")}
      />
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap={8}
      >
        {features.map((feature) => (
          <Feature key={feature.heading} {...feature} />
        ))}
      </Grid>
    </Box>
  );
}
