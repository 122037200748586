/* eslint-disable @next/next/no-img-element */
import {
  Text,
  Button,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import api from "~/core/api";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { showError } from "~/core/helpers";
import { toast } from "~/core/toast";

export default function NewsLetter() {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const email = (e.currentTarget as any).email.value;

    if (!email || !isEmail(email)) return;

    setIsLoading(true);
    api
      .signupNewsletter(email)
      .then(() =>
        toast({
          status: "success",
          title: "Success!",
          description: "You have been signed up for product updates!",
        })
      )
      .catch((err) => showError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      maxW={800}
      mx={"auto"}
      p={{ base: 4, md: 8, lg: 16 }}
    >
      <Text
        mb={4}
        textAlign={"center"}
        opacity={0.8}
        fontWeight={300}
        fontSize={"xl"}
      >
        Join our no-spam newsletter for a weekly summary of new and interesting
        projects.
      </Text>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
      >
        <InputGroup as="form" onSubmit={handleSubmit}>
          <Input
            placeholder="Email Address"
            id="email"
            type={"email"}
            rounded={"full"}
            fontWeight={300}
            backgroundColor={useColorModeValue("gray.100", "gray.900")}
          />
          <InputRightElement width={"auto"}>
            <Button
              type={"submit"}
              bg={"green.400"}
              color={"gray.50"}
              rounded={"full"}
              px={4}
              _hover={{
                bg: "green.500",
              }}
              isLoading={isLoading}
              fontWeight={300}
            >
              Get Hype Updates
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Flex>
  );
}
