import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { HYPE_BOT_LINK } from "~/core/constants";

export default function HomeDiscordBot() {
  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      py={8}
      px={16}
      m={4}
      mb={8}
      backgroundColor={useColorModeValue("gray.100", "gray.700")}
      borderRadius={24}
    >
      <Text
        fontSize={{ base: 18, md: 24, lg: 32 }}
        lineHeight={1.2}
        fontWeight={500}
        mb={2}
      >
        Invite Hype to your Discord Server
      </Text>
      <Text fontWeight={"hairline"} opacity={0.9} mb={8}>
        Run and manage giveaways and campaigns on your Discord server with Hype.
      </Text>

      <Button
        type={"submit"}
        bg={"green.400"}
        color={"gray.50"}
        rounded={"full"}
        px={4}
        _hover={{
          bg: "green.500",
        }}
        fontWeight={"400"}
        role="link"
        as="a"
        href={HYPE_BOT_LINK}
      >
        <Text>Invite Hype Bot</Text>
      </Button>
    </Flex>
  );
}
