import type { NextPage } from "next";
import dynamic from "next/dynamic";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CTA from "~/components/CTA";
import Features from "~/components/Features";
import { ProjectDocument } from "~/core/types";
import { getFeaturedProjects } from "~/core/firebaseQueries";
import firestoreToJson from "~/core/firestoreToJson";
import Embed from "~/components/Embed";
import NewsLetter from "~/components/NewsLetter";
import Link from "next/link";
import { HiArrowRight } from "react-icons/hi";
import HomeDiscordBot from "~/components/HomeDiscordBot";

const ExplainerVideo = dynamic(() => import("~/components/ExplainerVideo"), {
  ssr: false,
});

const Home: NextPage<any, any> = ({
  featuredProject,
}: {
  featuredProject: ProjectDocument;
}) => {
  return (
    <Box marginX="auto" maxW={1200}>
      <Flex
        borderRadius={24}
        m={4}
        px={8}
        py={8}
        bg={"#BB48B6"}
        color={"gray.50"}
        direction="column"
        boxShadow={"lg"}
      >
        <Link href={"/greeting-cards/valentines"}>
          <Flex
            direction={"row"}
            m={"auto"}
            alignItems={"center"}
            textAlign={"center"}
            fontWeight={300}
            fontSize={"2xl"}
            cursor={"pointer"}
          >
            Valentines on the blockchain! Send an NFT valentine in minutes.
            <Icon ml={2} as={HiArrowRight}></Icon>
          </Flex>
        </Link>
      </Flex>
      {/* <Box py={{ base: 2, md: 4 }}></Box> */}
      <CTA featuredProject={featuredProject} />
      <Box py={{ base: 2, md: 4 }}></Box>
      <Flex
        borderRadius={24}
        m={4}
        px={8}
        py={8}
        bg={useColorModeValue("gray.100", "gray.700")}
        direction="column"
      >
        <Link href={"/ai-minting/home"}>
          <Flex
            direction={"row"}
            m={"auto"}
            alignItems={"center"}
            textAlign={"center"}
            opacity={0.8}
            fontWeight={300}
            fontSize={"2xl"}
            cursor={"pointer"}
          >
            Create your own AI-generated NFT collection in minutes
            <Icon ml={2} as={HiArrowRight}></Icon>
          </Flex>
        </Link>
      </Flex>
      <Box py={{ base: 2, md: 4 }}></Box>
      <NewsLetter />
      <Box py={{ base: 2, md: 4 }}></Box>
      <Section
        heading="Grow your community with authentic users instead of mercenaries"
        text="Hate bots and bad actors? Us too. Create a campaign on Hype and use our wallet and social criteria to ensure you’re growing your community with the right people."
      />
      <Box py={{ base: 2, md: 4 }}></Box>
      <Section
        heading="Understand and segment your community"
        text="Do you know who’s in your community? Hype helps you complete the picture by connecting wallet addresses to social information like Twitter, Discord, and email."
        alternateLayout={true}
      />
      <Box py={{ base: 2, md: 4 }}></Box>
      <Section
        heading="Retain and reward your best community members"
        text="Mint and distribute NFTs and tokens to your most valuable community members. Create exclusive drops, event NFTs, heck, even your own community token, your creativity is the limit!"
      />
      <Box py={{ base: 2, md: 4 }}></Box>
      <Features />
      <Embed></Embed>
      <HomeDiscordBot />
      <ExplainerVideo />
    </Box>
  );
};

export default Home;

export async function getServerSideProps() {
  const featuredProjects = await getFeaturedProjects();
  return {
    props: {
      featuredProject:
        featuredProjects && featuredProjects[0]
          ? firestoreToJson(featuredProjects[0])
          : null,
    },
  };
}

function Section({
  heading,
  text,
  alternateLayout = false,
}: {
  heading: string;
  text: string;
  alternateLayout?: boolean;
}) {
  return (
    <Flex
      direction={{
        base: "column",
        md: !alternateLayout ? "row" : "row-reverse",
      }}
      alignItems={"center"}
      justifyContent={"center"}
      p={{ base: 4, md: 8, lg: 16 }}
      borderRadius={24}
    >
      <Heading
        fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
        fontFamily="'Lexend', sans-serif"
        fontWeight={"bold"}
        opacity={0.9}
        textAlign={{ base: "left", md: alternateLayout ? "right" : "left" }}
      >
        {heading}
      </Heading>
      <Box p={{ base: 4, md: 8 }}></Box>
      <Text opacity={0.9} fontSize={{ base: "lg", sm: "xl", md: "2xl" }}>
        {text}
      </Text>
    </Flex>
  );
}
