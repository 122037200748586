import Image, { ImageProps } from "next/image";
import { IMAGE_PREFIX } from "~/core/constants";

interface StorageImageProps extends Partial<ImageProps> {
  path: string;
}
export function ImagePath(path: string) {
  console.log("path", path);
  return IMAGE_PREFIX + path;
}

export default function StorageImage({ path, ...rest }: StorageImageProps) {
  return <Image alt="" {...rest} src={IMAGE_PREFIX + path} />;
}
