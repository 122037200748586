/* eslint-disable @next/next/no-img-element */
import {
  Box,
  Container,
  Text,
  Stack,
  Flex,
  useColorMode,
  Heading,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { ROUTES } from "~/core/constants";
import { ProjectDocument } from "~/core/types";
import FeaturedProject from "./FeaturedProject";
import TractionData from "./TractionData";
import NextLink from "next/link";

export default function CTA({
  featuredProject,
}: {
  featuredProject: ProjectDocument;
}) {
  const { colorMode } = useColorMode();

  return (
    <Container maxW={featuredProject ? "1200" : "1022"}>
      <Flex direction={{ base: "column", lg: "row" }}>
        <Stack
          as={Box}
          textAlign={
            featuredProject ? { base: "center", lg: "left" } : "center"
          }
          spacing={8}
          py={{ base: 12, md: 16 }}
          m={!featuredProject ? "auto" : "0"}
          px={12}
          borderRadius={24}
          backgroundColor={useColorModeValue("gray.100", "gray.700")}
        >
          <Text
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
            fontFamily="'Lexend', sans-serif"
            fontWeight={"bold"}
            lineHeight={1.2}
            textAlign={"center"}
          >
            Grow, engage, and retain a vibrant community with{" "}
            <Text
              as={"span"}
              fontFamily="'Bungee Inline', cursive"
              fontWeight={500}
            >
              HYPE
            </Text>
          </Text>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            display={{ base: "flex", xl: "none" }}
          >
            <NextLink href={ROUTES.publicProjects} passHref>
              <Button
                bg={"blue.400"}
                rounded={"full"}
                px={4}
                _hover={{
                  bg: "blue.500",
                }}
                color={"gray.50"}
                fontWeight={"400"}
              >
                Explore Campaigns
              </Button>
            </NextLink>
          </Flex>

          <Box alignSelf={"center"}>
            <a
              href="https://www.producthunt.com/posts/hype-day?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-hype&#0045;day"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=352382&theme=${colorMode}&period=daily`}
                alt="Hype&#0046;day - Build&#0032;high&#0045;quality&#0032;community&#0032;for&#0032;your&#0032;web3&#0032;projects&#0032;for&#0032;free | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </a>
          </Box>

          <TractionData></TractionData>
        </Stack>

        {featuredProject && (
          <>
            <Box p={6} display={{ base: "none", lg: "initial" }}></Box>

            <Flex
              my={"auto"}
              ml={"auto"}
              mt={{ base: 16, lg: "auto" }}
              mr={{ base: "auto", lg: 12 }}
              direction={"column"}
              mb={{ base: 4, lg: "auto" }}
            >
              <Heading
                mb={1}
                textAlign="center"
                fontFamily="'Bungee Inline', cursive"
                opacity={0.8}
              >
                Featured
              </Heading>
              <FeaturedProject
                project={featuredProject}
                actionLabel="Register Now"
              ></FeaturedProject>
            </Flex>
          </>
        )}
      </Flex>
    </Container>
  );
}
