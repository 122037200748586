import { Button, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";

export default function Embed() {
  return (
    <Flex
      direction={{ base: "column-reverse", sm: "row" }}
      alignItems={"center"}
      py={8}
      px={16}
      m={4}
      mb={8}
      backgroundColor={useColorModeValue("gray.100", "gray.700")}
      borderRadius={24}
    >
      <Flex direction={"column"} my={8} flex={1}>
        <Text
          fontSize={{ base: 18, md: 24, lg: 32 }}
          lineHeight={1.2}
          fontWeight={500}
          mb={2}
          mt={{ base: 0, sm: -18, md: -24, lg: -36 }}
        >
          Embed Hype Anywhere
        </Text>
        <Text fontWeight={"hairline"} opacity={0.9} mb={8}>
          Offer a streamlined experience for users by embedding Hype directly,
          whether you`re a web3 campaign or a marketplace.
        </Text>
        <Button
          as={"a"}
          bg={"green.400"}
          color={"gray.50"}
          rounded={"full"}
          width={{ base: "100%", md: "50%" }}
          alignSelf={"center"}
          px={4}
          _hover={{
            bg: "green.500",
          }}
          fontWeight={"400"}
          href={"https://github.com/StudioThree/hypeday-react"}
          target={"_blank"}
        >
          <Text>Go to Readme</Text>
        </Button>
      </Flex>
      <Flex m={4}></Flex>
      <Flex direction={"column"}>
        <Image
          width={{ base: "100%", sm: "8rem", md: "12rem", lg: "15rem" }}
          objectFit={"contain"}
          objectPosition={"center"}
          alt="embed hype"
          src="embed.png"
        ></Image>
      </Flex>
    </Flex>
  );
}
