import { Flex, Text } from "@chakra-ui/react";

export default function TractionData() {
  return (
    <Flex direction={"row"} opacity={0.75} letterSpacing={1}>
      <Flex flex={1}></Flex>
      <Flex direction={"column"}>
        <Text fontSize={"5xl"} fontWeight={"bold"}>
          50k+
        </Text>
        <Text>Collectors</Text>
      </Flex>
      <Flex m={6}></Flex>
      <Flex direction={"column"}>
        <Text fontSize={"5xl"} fontWeight={"bold"}>
          160+
        </Text>
        <Text>Campaigns</Text>
      </Flex>
      <Flex flex={1}></Flex>
    </Flex>
  );
}
