import NextLink from "next/link";
import { Box, Flex, Heading, Link, useColorModeValue } from "@chakra-ui/react";
import { ProjectDocument } from "~/core/types";
import StorageImage from "./StorageImage";
import { tsOrStringToDate } from "~/core/helpers";

export default function ProjectCard({
  project,
  to,
  maxW,
}: {
  project: ProjectDocument;
  to?: string;
  cta?: string;
  maxW?: string;
  btnStyles?: any;
}) {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const isExpired = (): boolean => {
    return Boolean(
      project.signupAccess &&
        project.signupAccess.isEndDateRequired &&
        project.signupAccess.endDate &&
        new Date().getTime() >
          tsOrStringToDate(project.signupAccess.endDate).getTime()
    );
  };

  return (
    <NextLink href={to || `/p/${project.slug}`} passHref>
      <Flex
        direction={"column"}
        pos={"relative"}
        w={{ base: "100%", sm: maxW || "270px" }}
        h={"270px"}
        bg={useColorModeValue("white", "gray.900")}
        rounded={"sm"}
        borderWidth={1}
        borderRadius={16}
        borderColor={borderColor}
        as={Link}
        _hover={{
          textDecoration: "none",
        }}
        marginTop={"30px"}
        marginBottom={"30px"}
      >
        {isExpired() && (
          <Box
            position={"absolute"}
            top={0}
            right={0}
            w={24}
            h={24}
            zIndex={1}
            overflow={"hidden"}
          >
            <Box
              pos={"absolute"}
              right={-7}
              top={4}
              py={1}
              overflow={"hidden"}
              px={8}
              background={"red.600"}
              color={"gray.100"}
              borderColor={borderColor}
              transform={"rotate(45deg)"}
              fontSize={"small"}
            >
              CLOSED
            </Box>
          </Box>
        )}

        <Box
          borderRadius={16}
          overflow={"hidden"}
          borderBottomWidth={0}
          borderColor={borderColor}
          position={"absolute"}
          top={0}
          left={0}
          height={"100%"}
          width={"100%"}
        >
          <StorageImage
            layout="fill"
            path={`project/${project.id}/${
              project.hasCoverImage ? "image_cover" : "banner"
            }.jpg?v=${project.version}`}
            objectFit={"cover"}
            objectPosition={"center"}
          />
        </Box>

        <Flex justify={"start"} mt={"-40px"} ml={4}>
          <Box
            width={"75px"}
            height={"75px"}
            rounded={"full"}
            overflow={"hidden"}
          >
            <StorageImage
              path={`project/${project?.id}/image.jpg?v=${project.version}`}
              width={75}
              height={75}
            />
          </Box>
        </Flex>

        <Flex flex={1} direction={"column"}>
          <Flex
            direction={"column"}
            overflow={"hidden"}
            position={"relative"}
            mt={"auto"}
            py={4}
            px={4}
            borderBottomRadius={16}
            backdropFilter={"blur(24px)"}
            bg={"rgba(39, 43, 48, 0.25)"}
          >
            <Heading
              my={4}
              fontSize={"2xl"}
              fontWeight={500}
              fontFamily={"body"}
              opacity={0.95}
              color={"gray.50"}
            >
              {project.name}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </NextLink>
  );
}
