import { Flex } from "@chakra-ui/react";

import { ProjectDocument } from "~/core/types";
import ProjectCard from "./ProjectCard";

export default function FeaturedProject({
  project,
  actionLabel,
}: {
  project: ProjectDocument;
  actionLabel: string;
}) {
  return (
    <Flex
      flex={1}
      w={{ base: "calc(100vw - 3rem)", lg: "22rem" }}
      direction="column"
    >
      <ProjectCard
        maxW="100%"
        key={project.id}
        project={project}
        cta={actionLabel}
        btnStyles={{
          color: "green.400",
          backgroundColor: "transparent",
          fontWeight: "bold",
          fontSize: "lg",
        }}
      />
    </Flex>
  );
}
